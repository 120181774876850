export default {
  name: "Login",
  data: function data() {
    return {
      //登录数据
      form: {
        username: '',
        password: '',
        code: '',
        checked: ''
      },
      //验证码图片信息
      code: {
        imgSrc: "",
        imgKey: ""
      }
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    //获取验证码图片
    init: function init() {
      var _this = this;
      this.service.axiosUtils({
        requestName: 'getCaptcha',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.code.imgSrc = res.data.url.img;
            _this.code.imgKey = res.data.url.key;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    //立即登录函数
    onSubmit: function onSubmit() {
      var _this2 = this;
      if (!this.form.username) {
        this.$toast.error('请输入登录账号');
        return;
      }
      if (!this.form.password) {
        this.$toast.error('请输入密码');
        return;
      }
      if (!this.form.code) {
        this.$toast.error('请输入图形验证码');
        return;
      }
      this.service.axiosUtils({
        requestName: 'Login',
        data: {
          mobile: this.form.username,
          password: this.form.password,
          captcha: this.form.code,
          key: this.code.imgKey
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success(res.message);
            //用户信息存入store+localStory中
            _this2.$store.commit('setUserInfo', res.data);
            _this2.Ls.setToken('nof_token', res.token_type + ' ' + res.access_token);
            _this2.initOrg();
            _this2.$router.push({
              path: '/index'
            });
          } else if (res.status === 2) {
            //验证码错误情况
            _this2.$toast.error(res.message);
            _this2.init();
            _this2.form.code = '';
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    initOrg: function initOrg() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: 'setOrgNo',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {} else {
            _this3.$toast.error(res.message);
          }
        }
      });
    }
  }
};