import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import Common from '../../assets/js/common.js';
import { nextTick } from 'vue';
import { uploadFile } from '../../api/qiniuApI';
import UploadLogo from '../common/UploadLogo';
export default {
  name: "AddOfflineSoft",
  props: ['resourcetypeDrop', 'formFile', 'isHideForeupdate', 'accept'],
  data: function data() {
    return {
      isFlag: false,
      dialogFormVisible: false,
      //是否显示弹框
      activeName: "folder",
      //选项卡
      addfileList: [],
      //文件列表
      fileArr: [],
      //传递完成的文件列表数量
      isMultiple: true,
      //是否支持多选文件
      fileListProgress: [],
      //进度
      dataList: [],
      //参数 时间 dataList
      sum: 0,
      //资源类型的总和
      isTrainType: 0,
      //是否显示培训资源
      subbmitState: false //上传失败，重新上传不再重新传文件
    };
  },
  created: function created() {},
  components: {
    UploadLogo: UploadLogo
  },
  mounted: function mounted() {
    // this.formFile.isClsResource=；
    // this.formFile.res.type&id
  },
  methods: {
    //资源类型产生变化的时候
    resourceTypeChange: function resourceTypeChange(val) {
      var type = 1;
      for (var i = 0; i < this.resourcetypeDrop.length; i++) {
        if (this.resourcetypeDrop[i].id == val) {
          type = this.resourcetypeDrop[i].type;
        }
      }
    },
    //是否是培训资源
    isTrainTypeFn: function isTrainTypeFn(type) {
      var value = type || this.formFile.type;
      //培训资源 或者 全选
      if (value == 2 || value == 3) {
        this.isTrainType = true;
        this.formFile.isTrainResource = 1; //是培训资源
      } else {
        this.isTrainType = false;
        this.formFile.isTrainResource = 0; //不是培训资源
        // this.formFile.canDownload=0; //不能下载
      }
    },
    //初始化type
    initType: function initType() {
      //判断选中的类型
      this.sum = 0;
      for (var i = 0; i < this.resourceTypeCheckboxType.list.length; i++) {
        var id = this.resourceTypeCheckboxType.list[i].id;
        this.sum += id;
      }
    },
    //设置资源封面
    setLogo: function setLogo(url) {
      this.formFile.cover = url;
    },
    //切换tab选项卡
    tabChange: function tabChange(name) {
      /*if(name == "folder"){
        this.oldRestypeId=this.formFile.restypeId;
        this.formFile.restypeId=1;
      }else{
        this.formFile.restypeId= this.oldRestypeId;
      }*/
    },
    //修改课程图标
    /*    changeFile(file, fileList) {
          let _this = this;
          _this.file=file.raw;
          var reader = new FileReader();
          reader.readAsDataURL(file.raw);
          reader.onload = function(e){
            // console.log(e)
            _this.courseData.cover = this.result;
          }
        },*/
    //资源改变
    changeFile: function changeFile(file, fileList) {
      this.addfileList = this.cloneFile(fileList);
    },
    //显示弹框
    showAddResource: function showAddResource(t) {
      //t:1 重传
      /*     if (t == 1){
             //url路径  存储下时长
             // this.oldDuration=this.formFile.urlList[0].duration;
             // alert( this.oldDuration)
             this.activeName="second";
             setTimeout(()=>{
               // console.log(this.formFile,this.resourcetypeDrop)
               this.oldDuration=this.formFile.duration;
               //存储下旧的url
               this.oldFileUrl=this.formFile.url;
      
             },100)
           }else {*/
      //添加的默认
      this.activeName = "first";
      /*     }*/
      this.dialogFormVisible = true;
      /* this.$nextTick(() => {
         setTimeout(()=>{
           document.querySelector(".uploadFolder .el-upload__input").webkitdirectory=true;
         },300)
       })*/
    },
    clickClose: function clickClose() {
      this.cancelDrawer();
    },
    //初始化弹框
    cancelDrawer: function cancelDrawer() {
      //清空typeId
      this.oldRestypeId = "";
      //取消上传
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      // this.$emit("resetData");
      //表单恢复初始化
      this.$refs.formuploadt.resetFields();
      //清空文件列表
      this.$refs.uploadFileF.clearFiles();
      //清空文件列表
      // this.$refs.uploadFolderF.clearFiles();
      //清空本地上传进度列表
      this.fileArr = [];
      //清空上传完成的个数
      this.addfileList = [];
      //清空传递的所有url
      this.formFile.urlList = [];

      //是否让点击上传
      this.isFlag = false;

      //弹框消失
      this.dialogFormVisible = false;

      //窗口关闭，重传也恢复初始化
      this.subbmitState = false;
    },
    //上传文件
    addResourceUploadFile: function addResourceUploadFile(formEl, upJson) {
      var _this2 = this;
      //设置资源上传完成钱都不可点击
      this.isFlag = true;
      var dateTime = new Date().getTime();
      var _this = this;
      var _loop = function _loop(i) {
        var file = _this2.addfileList[i];
        uploadFile({
          folderName: "resource",
          dateTime: dateTime,
          //唯一值
          duration: file.duration,
          //唯一值
          isHideLoading: true,
          //上传过程中是否显示遮罩层
          complete: function complete(res, url) {
            // console.log("上传完毕",url)
            // console.log(props.formFile)
            if (upJson.upType == "folder") {
              //html5
              // console.log(url,"_this.getUrlExt(url)",_this.getUrlExt(url))
              if (_this.getUrlExt(url) == "html") {
                // alert(url)
                _this.formFile.url = url;
                //普通文件
                var _json = {
                  url: url,
                  size: res.size,
                  name: res.name,
                  duration: 0
                };
                _this.formFile.urlList.push(_json);
                // alert(JSON.stringify(_this.formFile.urlList))
                // console.log("_this.formFile.urlList",_this.formFile.urlList)
              }

              var json = {
                url: url,
                name: _this.$common.getUrlName(url)
              };
              //上传完成file数组
              _this.fileArr.push(json);
            } else {
              _this.formFile.url = url;
              //普通文件
              var _json2 = {
                url: url,
                size: res.size,
                name: res.name,
                duration: res.duration
              };
              //上传完成file数组
              _this.fileArr.push(_json2);
              _this.formFile.urlList.push(_json2);
            }
            //如果全部都传完了的话就添加到数据库
            // console.log(_this.addfileList.length,_this.fileArr.length)
            // return;
            if (_this.addfileList.length == _this.fileArr.length) {
              _this.$emit("confirm", formEl);
              //关闭限制不可点击的按钮状态
              _this.isFlag = false;
              //开启上传url的状态
              _this.subbmitState = true;
            }
            //添加资源
          },
          //进度
          next: function next(res, subscription) {
            /* let json={
               name:"name",
               total:res.total
             }
             _this.fileListProgress[i]=json;*/
            _this.subscription = subscription;
            var percent = res.total.percent;
            _this.addfileList[i].percent = Math.floor(percent * 100) / 100;
            // console.log("_this.addfileList[i].percent",_this.addfileList[i].percent)
          }
        }, file.raw);
      };
      for (var i = 0; i < this.addfileList.length; i++) {
        _loop(i);
      }
    },
    //获取后缀名
    getUrlExt: function getUrlExt(url) {
      var arr = url.split("/");
      return arr[arr.length - 1].split(".")[1];
    },
    //确定提交的方法
    submitFn: function submitFn(formEl) {
      var _this3 = this;
      var _this = this;
      if (this.isFlag) {
        this.$toast.warning("请等待资源上传完成，请勿重复点击");
        return;
      }

      //如果文字资源已经上传完成了，但是写入数据库失败了
      if (this.subbmitState) {
        this.$emit("confirm", this.$refs[formEl]);
        return;
      }
      switch (this.activeName) {
        case "first":
          //上传文件
          this.$refs[formEl].validate(function (valid) {
            if (valid) {
              _this3.$toast.success("开始上传，请不要关闭此窗口");
              if (_this3.addfileList.length > 0) {
                // console.log("上传数据",this.formFile)

                //调用上传文件
                _this3.addResourceUploadFile(_this3.$refs[formEl], {
                  upType: 'file'
                });
              } else {
                _this3.$toast.error('请选择文件');
                return;
              }
            } else {}
          });
          break;
        case "folder":
          //上传文件夹
          this.$refs[formEl].validate(function (valid) {
            if (valid) {
              _this3.$toast.success("开始上传，请不要关闭此窗口");
              if (_this3.addfileList.length > 0) {
                //调用上传文件
                _this3.addResourceUploadFile(_this3.$refs[formEl], {
                  upType: 'folder'
                });
              } else {
                _this3.$toast.error('请选择文件夹');
                return;
              }
            } else {}
          });
          break;
        case "second":
          //上传url
          this.$refs[formEl].validate(function (valid) {
            if (valid) {
              _this3.$refs.formUrl.validate(function (validT) {
                if (validT) {
                  // alert(JSON.stringify(this.formFile))
                  var json = {
                    url: _this3.formFile.url,
                    name: _this3.$common.getUrlName(_this3.formFile.url),
                    size: 0,
                    duration: 0
                  };
                  if (_this3.oldFileUrl == _this3.formFile.url) {
                    // json.duration=this.oldDuration;
                    json.duration = _this3.formFile.duration;
                    json.size = _this3.formFile.size;
                  }
                  // alert(4444)
                  _this3.formFile.urlList.push(json);
                  // alert(5555)
                  //调用上传文件
                  _this3.$emit("confirm", _this3.$refs[formEl]);
                }
              });
            }
          });
          break;
        case "third":
          break;
      }
      // dialogFormVisible.value = false
    },
    //文件列表移除的钩子
    onRemoveFn: function onRemoveFn(file, fileList) {
      // console.log("onRemoveFn",file,fileList)
      this.addfileList = this.cloneFile(fileList);
    },
    //element ui对象转为原生file
    cloneFile: function cloneFile(fileList) {
      var _this4 = this;
      var arr = [];
      var _loop2 = function _loop2() {
        var file = fileList[i];
        var json = _objectSpread({}, fileList[i]);
        json.percent = -1;

        //如果是文件夹的话就是传课件，那就不读取时长了，否则性能一般的电脑浏览器会卡死崩溃
        if (_this4.activeName != "folder") {
          //搞视频、音频时长
          if (Common.checkResourceType(file.name) == "audio" || Common.checkResourceType(file.name) == "mp4") {
            var url = URL.createObjectURL(file.raw);
            var fileElement = new Audio(url);
            fileElement.addEventListener("loadedmetadata", function (_event) {
              json.duration = Math.ceil(fileElement.duration);
            });
          }
        }
        arr.push(json);
      };
      for (var i = 0; i < fileList.length; i++) {
        _loop2();
      }
      return arr;
    },
    initFn: function initFn() {
      //数据成功后初始化
      // this.activeName='first';
      // this.$refs.formUrl.resetFields();
      this.cancelDrawer();
    }
  }
};