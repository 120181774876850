import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import Hint from '../../components/common/Hint.vue';
import SearchSelect from '../../components/common/Search';
import AddResource from '../../components/popup/AddResource';
import AddOfflineResource from '../../components/popup/AddOfflineResource';
import EditorContainer from '../../components/popup/EditorContainer'; //图文
//图标
import { Search } from '@element-plus/icons-vue';
import Login from '../user/Login';
//获取上传
import UploadLogo from '../../components/common/UploadLogo';
//获取资源type类型
import { resourceTypeCheckboxType } from "../../assets/js/data";
import ResourceFolder from "../../components/courseResource/ResourceFolder";
import HeadmasterNavigation from "../../components/disk/HeadmasterNavigation";
export default {
  name: 'EditCourseResource',
  data: function data() {
    return {
      oldContent: "<p><br></p>",
      editTeachPlanDialogVisible: false,
      //修改教案提示
      moveCurrentIndex: -1,
      //鼠标当前移入的index
      contextmenuNode: null,
      //树状菜单右键的node
      currentJson: "",
      //树状菜单右键选中的
      isShowMenu: false,
      //是否显示右键弹出框
      topNumber: 0,
      //右键弹出框的定位
      leftNumber: 0,
      //右键弹出框的定位
      layoutOptions: [{
        label: "列表模式",
        value: 1
      }, {
        label: "缩略图模式",
        value: 2
      }],
      diskDataKey: 1,
      layoutType: parseInt(sessionStorage.getItem("layoutType")) || 1,
      //布局方式：1表格列表 2文件夹
      diskData: {
        dataList: []
      },
      path: [],
      diskNavData: '',
      courseTree: {},
      //课程信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      localCourse: JSON.parse(sessionStorage.getItem("course-" + this.$route.query.courseId)),
      defaultExpandAll: true,
      //是否默认展开所有节点
      sum: "",
      //类型循环的所有id加起来
      resourceTypeCheckboxType: resourceTypeCheckboxType,
      resourceTypeCheckboxTypeList: _toConsumableArray(resourceTypeCheckboxType.list),
      //类型循环的数据
      navData: [{
        //面包屑
        meta: {
          title: "课程"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程中心"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "资源管理"
        }
      }],
      isShow: false,
      //富文本编辑
      centerDialogVisible: false,
      dialogType: 1,
      //弹框类型
      dialog: {
        title: "删除",
        content: "确定删除该资源吗？"
      },
      urlOrContent: "url地址",
      //添加修改资源
      formFile: {
        name: '',
        //阶段
        courseId: this.$route.query.courseId,
        //课程ID
        structureId: '',
        //结构ID
        restypeId: '',
        //资源类型ID
        url: "",
        //7七牛地址
        urlList: [],
        //数组
        rank: 1,
        //排序
        state: 1,
        //状态：1正常 -1禁用
        isClsResource: 0,
        //是否是上课资源 1：表示是课程上课资源，0：表示不是课程上课资源，默认为1
        isTrainResource: 0,
        //是否是培训资源 1：培训资源 0：不是培训资源
        canDownload: 0,
        //是否允许下载
        cover: "",
        //cover封面
        content: "" //图文内容
      },

      //添加修改资源
      formFileResource: {
        name: '',
        //阶段
        isNewFile: 1,
        //新加资源
        courseId: this.$route.query.courseId,
        //课程ID
        structureId: '',
        //结构ID
        restypeId: '',
        //资源类型ID
        url: "",
        //7七牛地址
        urlList: [],
        //数组
        rank: 1,
        //排序
        state: 1,
        //状态：1正常 -1禁用
        isClsResource: 0,
        //是否是上课资源 1：表示是课程上课资源，0：表示不是课程上课资源，默认为1
        isTrainResource: 0,
        //是否是培训资源 1：培训资源 0：不是培训资源
        canDownload: 0,
        //是否允许下载
        cover: "",
        //cover封面
        content: "" //图文内容
      },

      resourcetypeDrop: [],
      //资源分类下拉
      courseResourceData: {},
      //添加修改资源
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      // courseData:JSON.parse(localStorage.getItem("course")),
      isAddResource: false,
      //是否显示添加资源的弹框
      total: 0,
      //页数总数
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 30),
      //每页个数
      //树形结构数据
      treeData: [],
      //树形结构数据
      defaultProps: {
        children: 'sublevel',
        label: 'name'
      },
      //左边菜单是否折叠
      isCollapse: false,
      //搜索关键字
      searchData: "",
      //search图标
      Search: Search,
      //表格数据
      tableData: [],
      currentKey: 1,
      // 筛选
      filter: [{
        placeholder: '资源类型状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '资源类型名称',
        value: this.$route.query.resName,
        type: 'text',
        list: []
      }]
    };
  },
  watch: {
    '$route': 'changeRouter'
  },
  created: function created() {
    //测试
    //   this.$emit('add');
    this.initData();
  },
  mounted: function mounted() {
    var _this = this;
    // let tablewrapper=document.querySelector(".table-wrapper");
    // let treewrapper=document.querySelector(".tree-wrapper");
    // let jwrapper=document.querySelector(".j-wrapper");
    // alert(jwrapper.offsetWidth)
    // tablewrapper.style.width=(jwrapper.offsetWidth-jwrapper.offsetWidth)+"px";
    //设置面包屑
    this.$emit('navigation', this.navData);

    //点击其他地方右键弹框消失
    document.addEventListener("click", function () {
      _this.isShowMenu = false;
    });
  },
  methods: {
    //删除
    handleRemove: function handleRemove() {
      var _this2 = this;
      var row = this.currentRow;
      this.service.axiosUtils({
        requestName: 'deleteResourceCover',
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //弹框消失
            _this2.centerDialogVisible = false;
            _this2.$toast.success("删除封面成功");
            _this2.initData();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    //筛选
    filterHandler: function filterHandler(value, row, column) {
      // console.log(value,row,column)
      return false;
    },
    //
    menuClick: function menuClick(t) {
      switch (t) {
        case 6:
          //删除

          this.detail(this.currentJson, 1);
          break;
      }
    },
    //右键单击树形菜单
    openTreeMenu: function openTreeMenu(event, data, node, target) {
      // console.log(event, data, node, target)

      this.currentJson = _objectSpread({}, data);
      this.currentJson.type = 0;
      // this.currentNode = node;
      this.contextmenuNode = node;
      this.topNumber = event.pageY;
      this.leftNumber = event.pageX;
      this.isShowMenu = true;
      // this.showTreeMenu = true // 显示菜单
      // this.contextNode = data // 存储数据
      // document.querySelector('.treeMenu').setAttribute('style',`top:${event.clientY}px;left:${event.clientX}px;`)
      // document.addEventListener('click', this.closeTreeMenu)
      // document.addEventListener('contextmenu', this.closeTreeMenu)
    },
    //改变视图
    changeView: function changeView() {
      sessionStorage.setItem("layoutType", this.layoutType);
      //如果是table列表就获取左边的树状结构
      if (this.layoutType == 1) {
        //获取左边树状结构
        this.coursestructureGetAll();
      }
      if (this.tableData.length == 0 || this.diskData.dataList.length == 0) {
        this.reloadList();
      }
    },
    //初始化路径
    //路径初始化
    initPathFn: function initPathFn() {
      this.path = [];
      this.pathT = this.$route.query.Path || this.diskNavData;
      var arrPath = this.pathT.split("/");
      var strPath = "";
      for (var i = 0; i < arrPath.length; i++) {
        // alert(arrPath[i].split(">"))
        var arrO = arrPath[i].split(">");
        // alert(arrO)
        if (i == 0) {
          strPath = arrO[0] + ">" + arrO[1];
        } else {
          strPath = strPath + "/" + arrO[0] + ">" + arrO[1];
        }
        var queryData = _objectSpread({}, this.$route.query);
        queryData.Path = strPath;
        var jsonPath = {
          text: arrO[0],
          path: "/course/edit/courseResource",
          name: "EditCourseResource",
          query: queryData
        };
        this.path.push(jsonPath);
      }
    },
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //获取当前选中的id和name
    getQueryTreeIdName: function getQueryTreeIdName() {
      var Path = this.$route.query.Path || this.diskNavData;
      var currentTreeArr = Path.split("/");
      var len = currentTreeArr.length - 1;
      // console.log("getQueryTreeIdName",currentTreeArr,len)
      var currentTreeIdArr = currentTreeArr[len].split(">");
      var json = {
        name: currentTreeIdArr[0],
        id: currentTreeIdArr[1]
      };
      return json;
    },
    //获取百度网盘结构的数据
    getSubItem: function getSubItem(tree, filter) {
      var _this3 = this;
      // this.currentTree.structureId=this.getQueryTreeIdName().id;
      // this.currentTree.structureName=this.getQueryTreeIdName().name;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        courseId: this.$route.query.courseId,
        selectedId: this.getQueryTreeIdName().id
      };

      // this.currentTree.courseId=this.$route.query.courseId
      // this.currentTree.pageIndex=this.$route.query.currentPage ? this.$route.query.currentPage : 1;
      // this.currentTree.pageSize=this.$route.query.pageSize ? this.$route.query.pageSize : 10;
      /*      var postData={
              ...this.currentTree
            }*/

      postData.userId = this.userInfo.userId;
      postData.roleId = this.userInfo.roleId;

      //搜索关键字
      if (filter) {
        if (filter.resName) {
          postData.resName = filter.resName;
        }
        if (filter.state) {
          postData.state = filter.state;
        }
      }
      this.service.axiosUtils({
        requestName: "coursestructureGetSubItem",
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this3.diskData.dataList = res.data;
            _this3.diskDataKey++;
          }
        }
      });
    },
    //左边的树状结构收起
    handleNodeChecked: function handleNodeChecked() {},
    //搜索左边的树状菜单
    searchTreeFn: function searchTreeFn() {
      this.coursestructureGetAll({
        structureName: this.searchData
      });
    },
    //初始化type
    initType: function initType() {
      //判断选中的类型
      this.sum = 0;
      for (var i = 0; i < this.resourceTypeCheckboxType.list.length; i++) {
        var id = this.resourceTypeCheckboxType.list[i].id;
        this.sum += id;
        //初始化数据
        this.resourceTypeCheckboxTypeList[i].isChecked = true;
      }
      this.drawerData.type = this.sum;
    },
    //点击传递封面存储以前的数据
    tabCurrentForm: function tabCurrentForm(row) {
      this.currentRow = row;
    },
    //传递完封面的回调函数
    setLogo: function setLogo(cover, row) {
      this.currentRow = row;
      this.formFile = _objectSpread({}, this.currentRow);
      this.currentRow.cover = cover;
      // console.log("this.currentRow",this.currentRow)
      if (this.currentRow.url) {
        //传资源文件的话要保证content为空
        this.formFile.content = "";
        var arr = [{
          url: this.currentRow.url,
          size: this.currentRow.size,
          name: this.currentRow.name,
          duration: this.currentRow.duration
        }];
        this.formFile.urlList = arr;
      } else {
        //传图文的话urllist要保证为空
        this.formFile.urlList = [];
      }
      this.formFile.cover = cover;

      //调用修改封面接口
      this.editCourseResourceCover();
    },
    //修改封面
    editCourseResourceCover: function editCourseResourceCover(formEl, json) {
      var _this4 = this;
      this.formFile.structureId = this.currentTree.id;
      this.formFile.name = this.getUrlName(this.formFile.url);
      var json = json || {};
      var requestName = "";
      requestName = "editCourseResource";
      var postData = _objectSpread({}, this.formFile);
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this4.$toast.success("修改封面成功");
            _this4.currentRow.cover = _this4.formFile.cover;
            //回复初始化
            _this4.formFile = {
              name: '',
              //阶段
              courseId: _this4.$route.query.courseId,
              //课程ID
              structureId: '',
              //结构ID
              restypeId: '',
              //资源类型ID
              url: "",
              //7七牛地址
              urlList: [],
              //数组
              rank: 1,
              //排序
              state: 1,
              //状态：1正常 -1禁用
              isClsResource: 1,
              //是否是上课资源 1：表示是课程上课资源，0：表示不是课程上课资源，默认为1
              canDownload: 0,
              //是否允许下载
              cover: "",
              //cover封面
              content: "" //图文内容
            };
            //this.initData();
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    //修改课程资源状态
    //是否是上课资源
    changeIsClsResource: function changeIsClsResource(value) {
      var _this5 = this;
      var postData = {
        id: value.id
      };
      if (value.isClsResource == 1) {
        postData.isClsResource = 0;
      } else {
        postData.isClsResource = 1;
      }
      this.service.axiosUtils({
        requestName: 'changeClsResource',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this5.$toast.success(res.message);
          }
          _this5.initData();
        }
      });
    },
    /*    // 通过节点的key（这里使用的是数据中的code属性，node-key="code"）获取并高亮显示指定节点，并展开其所有父级节点
        getAndExpandAll(nodeKey) {
           if (nodeKey) {
            this.$nextTick(() => { // 等待树组件渲染完成再执行相关操作
              // 获取节点
              const node = this.$refs.tree.getNode(this.currentTree)
              console.log("node",node);
              if (node) {
                // 获取其所有父级节点
                this.getParentAll(node)
                if (this.nodeParentAll.length > 0) {
                  // 将获取到的所有父级节点进行展开
                  for (var i = 0, n = this.nodeParentAll.length; i < n; i++) {
                    this.$refs.tree.store.nodesMap[this.nodeParentAll[i].data.id].expanded = true
                  }
                }
                 // 将节点高亮显示
                this.$refs.tree.setCurrentKey(nodeKey)
              }
            })
          }
        },
        // 获取所有父级节点
        getParentAll(node) {
          if (node) {
            this.nodeParentAll = []
            // 节点的第一个父级
            var parentNode = node.parent
            // level为节点的层级 level=1 为顶级节点
            for (var j = 0, lv = node.level; j < lv; j++) {
              if (parentNode.level > 0) {
                // 将所有父级节点放入集合中
                this.nodeParentAll.push(parentNode)
              }
              // 继续获取父级节点的父级节点
              parentNode = parentNode.parent
            }
             if (this.nodeParentAll.length > 1) {
              // 如果集合长度>1 则将数组进行倒叙.reverse() 其是就是将所有节点按照 从 顶级节点 依次往下排
              this.nodeParentAll.reverse()
            }
          }
        },*/
    //搜索
    onSubmit: function onSubmit() {},
    filterFn: function filterFn() {
      var _this6 = this;
      var obj = {
        a: false,
        b: false,
        c: false
      };
      this.formData.forEach(function (v) {
        _this6.checkedList.find(function (item) {
          if (v == item) {
            obj[v] = true;
          }
        });
      });
      this.objData = obj;
    },
    //获取列数据
    filesizeConvert: function filesizeConvert(row, column, cellValue) {
      this.formFile = _objectSpread({}, this.currentRow);
      this.formFile.id = this.currentRow.id;
      this.formFile.urlList = [];
      this.formFile.cover = this.currentRow.cover;
    },
    cancelEditor: function cancelEditor() {
      this.oldContent = "<p><br></p>";
      this.isShow = false;
      //数据恢复初始化
      this.formFile = {
        name: '',
        //阶段
        courseId: this.$route.query.courseId,
        //课程ID
        structureId: '',
        //结构ID
        restypeId: '',
        //资源类型ID
        url: "",
        //7七牛地址
        urlList: [],
        //数组
        rank: 1,
        //排序
        state: 1,
        //状态：1正常 -1禁用
        isClsResource: 0,
        //是否是上课资源 1：表示是课程上课资源，0：表示不是课程上课资源，默认为1
        isTrainResource: 0,
        //是否是培训资源 1：培训资源 0：不是培训资源
        canDownload: 0,
        //是否允许下载
        cover: "",
        //cover封面
        content: "" //图文内容
      };
    },
    cancelSoftLineEditor: function cancelSoftLineEditor() {
      this.isShow = false;
      //数据恢复初始化
      this.formFileResource = {
        name: '',
        //阶段
        courseId: this.$route.query.courseId,
        //课程ID
        structureId: '',
        //结构ID
        restypeId: '',
        //资源类型ID
        url: "",
        //7七牛地址
        urlList: [],
        //数组
        rank: 1,
        //排序
        state: 1,
        //状态：1正常 -1禁用
        isClsResource: 0,
        //是否是上课资源 1：表示是课程上课资源，0：表示不是课程上课资源，默认为1
        isTrainResource: 0,
        //是否是培训资源 1：培训资源 0：不是培训资源
        canDownload: 0,
        //是否允许下载
        cover: "",
        //cover封面
        content: "" //图文内容
      };
    },
    //图文保存
    saveBack: function saveBack(json) {
      var json = json || {};
      console.log(this.oldContent + "==" + json.content);
      //判断如果教案有改动再提示弹框请求接口，没有改动则不请求
      if (this.oldContent == json.content) {
        this.cancelEditor();
        return;
      }
      this.editTeachPlanJson = json;
      if (this.formFile.id) {
        this.editTeachPlanDialogVisible = true;
      } else {
        this.addEditTeachPlan();
      }
    },
    addEditTeachPlan: function addEditTeachPlan() {
      var _this7 = this;
      var json = this.editTeachPlanJson;
      var requestName = "";
      this.formFile.content = json.content;
      this.formFile.name = json.name;
      this.formFile.structureId = this.currentTree.id;
      //传图文的话urllist要保证为空
      this.formFile.urlList = [];
      var postData = _objectSpread({}, this.formFile);
      delete postData.url;
      if (this.formFile.id) {
        //编辑
        requestName = "editCourseResource";
      } else {
        //保存
        requestName = "addCourseResource";
      }
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this7.editTeachPlanDialogVisible = false;
            //初始化有就调用
            // this.$refs.childRef.initFn && this.$refs.childRef.initFn();
            // this.$refs.childRef.dialogFormVisible=false;
            _this7.$toast.success(res.message);
            _this7.cancelEditor();
            _this7.initData();
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    detail: function detail(row, type) {
      //type 1删除 5删除封面

      this.dialogType = type;
      this.currentRow = row;
      switch (parseInt(type)) {
        case 1:
          this.centerDialogVisible = true;
          //删除
          this.dialog.title = "删除";
          this.dialog.content = "确定删除 <span style='color: #3f86f8;'>" + row.name + "</span> 吗？";
          break;
        case 2:
          this.centerDialogVisible = true;
          //禁用
          if (row.state == 1) {
            this.dialog.title = "禁用";
            this.dialog.content = "确定禁用 " + row.name + " 吗？";
          } else {
            this.dialog.title = "启用";
            this.dialog.content = "确定启用 " + row.name + " 吗？";
          }
          break;
        case 3:
          //上传
          //重新传
          if (row.isOfflineResource == 1) {
            //离线
            this.showAddOfflineResource(1);
          } else {
            //普通
            this.showAddResource(1);
          }
          break;
        case 4:
          //图文编辑
          this.formFile = _objectSpread({}, this.currentRow);
          this.formFile.id = this.currentRow.id;
          this.formFile.urlList = [];
          //赋值旧的教案
          this.oldContent = this.currentRow.content;
          // console.log(this.formFile)
          this.showEditor(1);
          break;
        case 5:
          //删除封面
          this.centerDialogVisible = true;
          this.dialog.title = "删除封面";
          this.dialog.content = "确定删除 <span style='color: #3f86f8;'>" + row.name + "</span> 的封面图吗？";
          break;
      }
    },
    //删除资源
    deleteFn: function deleteFn(row) {
      var _this8 = this;
      var requestName = "";
      row = this.currentRow;
      if (this.currentRow.type == 1) {
        //资源
        requestName = "deleteCourseResource";
      } else {
        //文件
        requestName = "deleteCourseStructure";
      }
      this.service.axiosUtils({
        requestName: requestName,
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this8.$toast.success(res.message);
            _this8.centerDialogVisible = false;
            if (_this8.currentRow.type != 1) {
              //文件夹层级
              //缓存下父级节点，要不remove之后阶段就为空了
              var oldCurrentNodeParent = _this8.contextmenuNode.parent;
              //如果删除的是当前的层级就更新tree和table
              if (_this8.currentJson.id == _this8.currentTree.id) {
                _this8.$refs.tree.remove(_this8.contextmenuNode);
                if (oldCurrentNodeParent.childNodes.length > 0) {
                  _this8.currentTree = oldCurrentNodeParent.childNodes[0].data;
                } else {
                  _this8.currentTree = oldCurrentNodeParent.data;
                }
                //手动更新tree当前选中的值
                _this8.$refs["tree"].setCurrentKey(_this8.currentTree.id);
                //触发点击跳转
                _this8.handleNodeClick(_this8.currentTree);
              } else {
                //不一样的话直接删除就可以
                _this8.$refs.tree.remove(_this8.contextmenuNode);
              }
            }
            //刷新列表
            _this8.reloadList();
          } else {
            _this8.$toast.error(res.message);
          }
          //获取table
          //先暂时注释
          // this.courseresourceGetList(this.currentTree);
          /* if(this.layoutType == 1){
             //获取table格式
             this.courseresourceGetList(this.currentTree);
           }else{
             //获取网盘格式
             this.getSubItem(this.currentTree);
           }*/
        }
      });
    },
    //正常禁用
    changeResourceState: function changeResourceState(value) {
      var _this9 = this;
      var postData = {
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'courseresourceChangeState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this9.$toast.success(res.message);
            _this9.centerDialogVisible = false;
          } else {
            _this9.$toast.error(res.message);
          }
          if (_this9.layoutType == 1) {
            //获取table格式
            _this9.courseresourceGetList(_this9.currentTree);
          } else {
            //获取网盘格式
            _this9.getSubItem(_this9.currentTree);
          }
        }
      });
    },
    //修改状态
    changeState: function changeState(value) {
      var _this10 = this;
      value = this.currentRow;
      var postData = {
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'courseresourceChangeState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this10.$toast.success(res.message);
            _this10.centerDialogVisible = false;
          } else {
            _this10.$toast.error(res.message);
          }
          //获取table
          _this10.courseresourceGetList(_this10.currentTree);
        }
      });
    },
    getUrlName: function getUrlName(url) {
      var arr = url.split("/");
      return arr[arr.length - 1].split(".")[0];
    },
    //点击确定执行的方法
    confirmClick: function confirmClick(formEl) {
      this.formFile.structureId = this.currentTree.id;
      this.formFile.name = this.getUrlName(this.formFile.url);
      //开始添加资源
      this.addCourseResource(formEl);
    },
    //离线软件确定
    confirmClickOffline: function confirmClickOffline(formEl) {
      this.formFileResource.structureId = this.currentTree.id;
      this.formFileResource.name = this.getUrlName(this.formFileResource.url);
      //开始添加资源
      this.addOfflineCourseResource(formEl, {
        isOfflineResource: 1
      });
    },
    initData: function initData() {
      //设置面包屑
      this.$emit('navigation', this.navData);
      //获取左边树状结构
      this.coursestructureGetAll();
      //默认选中左边的第一个
      //获取下拉
      this.resourcetypeGetAll();
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      //再重新设置路由
      this.$emit('navigation', this.navData);
      if (!json) {
        this.currentTree = this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {};
      }
      this.filter[1].value = this.$route.query.resName || "";
      this.filter[0].value = this.$route.query.state || "";
      this.filterJson = {
        resName: this.filter[1].value,
        state: this.filter[0].value
      };

      //初始化路由
      this.initPathFn();

      //获取数据，刷新列表
      this.reloadList();

      /* if(this.layoutType == 1){
         //获取table格式
         this.courseresourceGetList(this.currentTree,this.filterJson);
       }else{
         //获取网盘格式
         this.getSubItem(this.currentTree,this.filterJson);
       }*/
    },
    //判断下是table还是网盘重新刷新列表
    reloadList: function reloadList() {
      if (this.layoutType == 1) {
        //获取列表table格式
        this.courseresourceGetList(this.currentTree, this.filterJson);
      } else {
        //获取网盘格式
        this.getSubItem(this.currentTree, this.filterJson);
      }
    },
    //设置当前选中的节点
    setCheckedKeys: function setCheckedKeys() {},
    //获取下拉
    resourcetypeGetAll: function resourcetypeGetAll() {
      var _this11 = this;
      this.service.axiosUtils({
        requestName: 'resourcetypeGetAll',
        data: {
          courseId: this.$route.query.courseId
        },
        sucFn: function sucFn(res) {
          //普通
          _this11.resourcetypeDrop = _this11.getResourcetypeDrop(res.data, 1);
          //离线
          _this11.resourcetypeDropOffline = _this11.getResourcetypeDrop(res.data, 2);
          // console.log(res.data);
        }
      });
    },
    //获取资源分类下拉
    getResourcetypeDrop: function getResourcetypeDrop(data, type) {
      var arr = [];
      if (type == 1) {
        //普通资源
        for (var i = 0; i < data.length; i++) {
          if (data[i].type != 4) {
            arr.push(data[i]);
          }
        }
      } else {
        //离线资源
        for (var _i = 0; _i < data.length; _i++) {
          if (data[_i].type == 4) {
            arr.push(data[_i]);
          }
        }
      }
      return arr;
    },
    //获取左边的树状结构
    coursestructureGetAll: function coursestructureGetAll(postJson) {
      var _this12 = this;
      var postData = {
        courseId: this.$route.query.courseId
      };
      //如果有搜索字段的话就加上
      if (postJson) {
        postData.structureName = postJson.structureName;
      }
      this.service.axiosUtils({
        requestName: 'coursestructureGetAll',
        data: postData,
        sucFn: function sucFn(res) {
          _this12.treeData = res.data;
          //赋值当前课程信息
          _this12.courseTree = res.data[0];

          // console.log("treeData",this.treeData)
          //设置下最初的path
          _this12.diskNavData = _this12.courseTree.name + ">" + _this12.courseTree.id;
          // delete this.courseTree.sublevel
          if (!_this12.$route.query.tree) {
            _this12.currentTree = _this12.treeData[0];
            _this12.changeRouter(_this12.currentTree);
          } else {
            _this12.currentTree = JSON.parse(_this12.$route.query.tree);
            _this12.changeRouter();
          }
          _this12.$nextTick(function () {
            _this12.$refs.tree.setCurrentKey(_this12.currentTree.id);
          });
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      // this.initData();
      var query = _objectSpread({}, this.$route.query);
      delete query.resName;
      delete query.state;
      this.$router.push({
        path: this.$route.path,
        query: query
      });
    },
    //全部收起
    expandHandle: function expandHandle() {
      // console.log(this.$refs.tree.store);
      this.expandAll = false;
      this.expandNodes(this.$refs.tree.store.root);
    },
    // 遍历树形数据，设置每一项的expanded属性，实现展开收起
    expandNodes: function expandNodes(node) {
      node.expanded = this.expandAll;
      for (var i = 0; i < node.childNodes.length; i++) {
        node.childNodes[i].expanded = this.expandAll;
        if (node.childNodes[i].childNodes.length > 0) {
          this.expandNodes(node.childNodes[i]);
        }
      }
    },
    //搜索资源
    searchNameList: function searchNameList(filterArrj) {
      var _this13 = this;
      var name = filterArrj[1].value;
      var state = filterArrj[0].value;
      var postData = {
        courseId: this.$route.query.courseId
      };
      var query = _objectSpread({}, this.$route.query);
      if (name) {
        // postData.resName=name;
        query.resName = name;
      } else {
        delete query.resName;
      }
      if (state) {
        // postData.state=state;
        query.state = state;
      } else {
        delete query.state;
      }
      this.currentTree = {};
      delete query.tree;
      var path = this.$route.path;
      /*      //收起所有节点
            this.defaultExpandAll=false;*/
      this.$router.replace({
        path: path,
        query: query
      });
      this.$nextTick(function () {
        //清空高亮
        _this13.$refs.tree.setCurrentKey(null);
        //全部收起
        _this13.expandHandle();
      });

      //搜索
      /* this.service.axiosUtils({
         requestName: 'courseresourceGetList',
         data: postData,
         sucFn: res => {
           this.tableData=res.data;
         }
       })*/
    },
    //获取table列表
    courseresourceGetList: function courseresourceGetList(tree, filter) {
      var _this14 = this;
      this.currentTree.structureId = this.currentTree.id;
      this.currentTree.structureName = this.currentTree.name;
      this.currentTree.courseId = this.$route.query.courseId;
      this.currentTree.pageIndex = this.$route.query.currentPage ? this.$route.query.currentPage : 1;
      this.currentTree.pageSize = this.$route.query.pageSize ? this.$route.query.pageSize : 10;
      // this.currentTree.resName="课件";//搜索字段
      var postData = _objectSpread({}, this.currentTree);

      //搜索关键字
      /*if(this.$route.query.resName){
        postData.resName=this.$route.query.name;
      }*/
      if (filter) {
        if (filter.resName) {
          postData.resName = filter.resName;
        }
        if (filter.state) {
          postData.state = filter.state;
        }
      }

      // state
      this.service.axiosUtils({
        requestName: 'courseresourceGetList',
        data: postData,
        sucFn: function sucFn(res) {
          //this.currentTree
          _this14.tableData = res.data;
          // this.total = res.data.total;
        }
      });
    },
    //点击某一个节点
    handleNodeClick: function handleNodeClick(data) {
      this.currentTree = data;
      //删除树状菜单的子级，否则会因为数据太多挂到地址栏超出地址栏的最大字符导致数据不全的问题
      delete this.currentTree.sublevel;
      this.currentTree.courseId = this.$route.query.courseId;
      // this.currentTree.pageSize=10;
      // this.currentTree.pageIndex=1;
      this.currentTree.structureId = this.currentTree.id;
      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      // 获取最大页数
      var pageMax = Math.ceil(this.total / this.pageSize);
      // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
      if (this.currentPage > pageMax) {
        this.currentPage = pageMax;
      }
      var queryData = _objectSpread({}, this.$route.query);
      queryData.currentPage = this.currentPage;
      queryData.pageSize = page;
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      var queryData = _objectSpread({}, this.$route.query);
      queryData.currentPage = this.currentPage;
      queryData.pageSize = this.$route.query.pageSize ? this.$route.query.pageSize : 10;
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    //显示添加离线资源弹框
    showAddOfflineResource: function showAddOfflineResource(isEdit) {
      var _this15 = this;
      if (isEdit == 1) {
        //修改
        this.$refs.childRefOffline.showAddResource(1);
        setTimeout(function () {
          _this15.formFileResource = _objectSpread({}, _this15.currentRow);
          _this15.formFileResource.id = _this15.currentRow.id;
          _this15.formFileResource.urlList = [];
          _this15.formFileResource.cover = _this15.currentRow.cover;
          //资源类型初始化
          _this15.$refs.childRefOffline.resourceTypeChange(_this15.formFileResource.restypeId);
        }, 30);
      } else {
        //添加
        if (this.resourcetypeDropOffline.length == 1) {
          this.formFileResource.restypeId = this.resourcetypeDropOffline[0].id;
        }
        //默认选中课间
        this.formFileResource.id = "";
        this.formFileResource.url = "";
        // this.formFile.restypeId=1;
        this.formFileResource.urlList = [];
        //初始化type资源类型
        // this.initType();
        this.$refs.childRefOffline.showAddResource();
      }
    },
    //显示添加资源文件弹框
    showAddResource: function showAddResource(isEdit) {
      var _this16 = this;
      if (isEdit == 1) {
        //重传
        this.$refs.childRef.showAddResource(1);
        setTimeout(function () {
          _this16.formFile = _objectSpread({}, _this16.currentRow);
          _this16.formFile.id = _this16.currentRow.id;
          _this16.formFile.urlList = [];
          _this16.formFile.cover = _this16.currentRow.cover;
          //资源类型初始化
          _this16.$refs.childRef.resourceTypeChange(_this16.formFile.restypeId);
          //转换type (多选的时候才转，现在是开关，不转)
          /* for (let i = 0; i < this.resourceTypeCheckboxTypeList.length; i++) {
             let id = this.resourceTypeCheckboxTypeList[i].id;
             if((this.drawerData.type&id) > 0){
               this.resourceTypeCheckboxTypeList[i].isChecked=true;
             }else{
               this.resourceTypeCheckboxTypeList[i].isChecked=false;
             }
           }*/
        }, 30);
      } else {
        //默认选中课间
        this.formFile.id = "";
        this.formFile.restypeId = 1;
        this.formFile.urlList = [];
        //初始化type资源类型
        // this.initType();
        this.$refs.childRef.showAddResource();
      }
    },
    //显示添加图文内容弹框
    showEditor: function showEditor(isEdit) {
      if (isEdit == 1) {
        //编辑
      } else {
        //添加
        this.formFile.restypeId = "";
        this.formFile.name = "";
      }
      this.isShow = true;
    },
    //添加离线资源文件
    addOfflineCourseResource: function addOfflineCourseResource(formEl, json) {
      var _this17 = this;
      var json = json || {};
      var requestName = "";

      //传资源文件的话要保证content为空

      var postData = _objectSpread({}, this.formFileResource);
      if (this.formFileResource.id) {
        postData.isNewFile = 1;
        for (var i = 0; i < postData.urlList.length; i++) {
          postData.urlList[i].version = postData.version;
        }
        requestName = "editCourseResource";
      } else {
        requestName = "addCourseResource";
        for (var jsonKey in json) {
          postData[jsonKey] = json[jsonKey];
        }
      }
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //初始化有就调用
            if (json.isOfflineResource == 1) {
              _this17.$refs.childRefOffline.initFn && _this17.$refs.childRefOffline.initFn();
            } else {
              _this17.$refs.childRef.initFn && _this17.$refs.childRef.initFn();
            }
            // this.$refs.childRef.dialogFormVisible=false;
            _this17.$toast.success(res.message);
            _this17.cancelSoftLineEditor();
            _this17.initData();
            /*if(json.formEl){
              //表单数据初始化
              json.formEl.resetFields();
            }*/

            //表单数据初始化
            // formEl.resetFields();
            // this.cancelDrawer();
          } else {
            _this17.$toast.error(res.message);
          }
        }
      });
    },
    //添加资源文件
    addCourseResource: function addCourseResource(formEl, json) {
      var _this18 = this;
      var json = json || {};
      var requestName = "";

      //传资源文件的话要保证content为空
      this.formFile.content = "";
      var postData = _objectSpread({}, this.formFile);
      if (this.formFile.id) {
        requestName = "editCourseResource";
      } else {
        requestName = "addCourseResource";
      }
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //初始化有就调用
            _this18.$refs.childRef.initFn && _this18.$refs.childRef.initFn();

            // this.$refs.childRef.dialogFormVisible=false;
            _this18.$toast.success(res.message);
            _this18.initData();
            /*if(json.formEl){
              //表单数据初始化
              json.formEl.resetFields();
            }*/

            //表单数据初始化
            // formEl.resetFields();
            // this.cancelDrawer();
          } else {
            _this18.$toast.error(res.message);
          }
        }
      });
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      // this.isCollapse=!this.isCollapse;
      if (this.isCollapse) {
        this.isCollapse = false;

        //收起
        /*        let aElTree=document.querySelectorAll(".el-tree-node__label");
                console.log(aElTree.length)
                 for(let i=0; i<aElTree.length;i++){
                  aElTree[i].style.display="none !important";
                }*/
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    handleOpen: function handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose: function handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  components: {
    Login: Login,
    Hint: Hint,
    SearchSelect: SearchSelect,
    AddResource: AddResource,
    EditorContainer: EditorContainer,
    UploadLogo: UploadLogo,
    ResourceFolder: ResourceFolder,
    HeadmasterNavigation: HeadmasterNavigation,
    AddOfflineResource: AddOfflineResource
  }
};